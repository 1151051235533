import logo from './logo.svg';
import './App.css';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';                             


function App() {
  return (
    <section className='bg-header'>
      <div className='width-container'>
        <div className='flex-header'>
          <div className='logo'>
            <img src="assets/images/logo.png" />
          </div>
          {/* <div className='links-menus'>
            <ul>
              <li><a>Home</a></li>
              <li><a>Property</a></li>
              <li><a>About Us</a></li>
              <li><a>Contact Us</a></li>
            </ul>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default App;
